// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import TopMenu from "../../../components/TopMenu/index";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import GoogleMapsContainer from "./GoogleMap";

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";
import { CONTACT_US } from "graphql/mutation/contactus";
// Image
import { useMutation } from "@apollo/client";
import { useState } from "react";
import SnackbarAlert from "../../../common/SnackbarAlert";

// Image
import bgImage from "../../../assets/images/cover-1.webp";

function ContactUs() {
  const [getName, setName] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getMessage, setMessage] = useState("");
  const [getNameError, setNameError] = useState("");
  const [getEmailError, setEmailError] = useState("");
  const [getPhoneError, setPhoneError] = useState("");
  const [getMessageError, setMessageError] = useState("");
  const [showSnackBarAlert, setShowSnackBarAlert] = useState({
    status: false,
    type: "success",
    message: null,
  });
  const [contactForm] = useMutation(CONTACT_US, {
    onCompleted: () => {
      setShowSnackBarAlert({ status: true, type: "success", message: "Message sent" });
      window.location.reload(true);
    },
  });
  const handleSubmit = () => {
    event.preventDefault();
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (getName.length === 0) {
      setNameError("Name is Required");
    } else {
      setNameError("");
    }
    if (getEmail.length === 0) {
      setEmailError("Email is Required");
    } else if (pattern.test(getEmail) == false) {
      setEmailError("Enter valid email address");
    } else {
      setEmailError("");
      return;
    }
    if (getMessage.length === 0) {
      setMessageError("Message is Required");
    } else {
      setMessageError("");
    }
    if (getPhone.length === 0) {
      setPhoneError("Phone Number is Required");
    } else if (getPhone.length !== 10) {
      setPhoneError("Enter Valid Phone Number");
    }

    contactForm({
      variables: {
        contactInput: {
          name: getName,
          email: getEmail,
          phone: getPhone,
          message: getMessage,
        },
      },
    });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  return (
    <>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        setShowSnackBarAlert={setShowSnackBarAlert}
      />
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Contact Us{" "}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={6} xl={6}>
            <MKBox
              display={{ lg: "flex" }}
              width="calc(100% - 2rem)"
              sx={{ height: { lg: "calc(80vh - 2rem)", xs: "100%" } }}
              borderRadius="lg"
              ml={2}
              mt={2}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15723.953331001881!2d76.3840443!3d9.8513452!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc92bf5f2a9ce616a!2sSahodaran%20Ayyappan%20Memorial%20College%20Of%20Education!5e0!3m2!1sen!2sin!4v1648534270305!5m2!1sen!2sin"
                width="100%"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 5, sm: 9, md: 10 }}
              mb={{ xs: 5, sm: 9, md: 10 }}
              mx={3}
            >
              <MKBox
                bgColor="warning"
                coloredShadow="warning"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MKTypography variant="h3" color="white">
                  Contact Us
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => handleNameChange(e)}
                      />
                      {getNameError.length !== 0 ? (
                        <MKTypography
                          variant="button"
                          color="error"
                          textGradient
                          fontWeight="regular"
                        >
                          {getNameError}
                        </MKTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Phone"
                        type="Tel"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => handlePhoneChange(e)}
                      />
                      {getPhoneError.length !== 0 ? (
                        <MKTypography
                          variant="button"
                          color="error"
                          textGradient
                          fontWeight="regular"
                        >
                          {getPhoneError}
                        </MKTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => handleEmailChange(e)}
                      />
                      {getEmailError.length !== 0 ? (
                        <MKTypography
                          variant="button"
                          color="error"
                          textGradient
                          fontWeight="regular"
                        >
                          {getEmailError}
                        </MKTypography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="How can we help you?"
                        // placeholder="Describe your enquiry"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                        onChange={(e) => handleMessageChange(e)}
                      />
                      {getMessageError.length !== 0 ? (
                        <MKTypography
                          variant="button"
                          color="error"
                          textGradient
                          fontWeight="regular"
                        >
                          {getMessageError}
                        </MKTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton
                      type="submit"
                      color="warning"
                      coloredShadow="warning"
                      onClick={handleSubmit}
                    >
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
