// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";
import principalImg from "../../../../../assets/images/principal.png";
// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox/index";
import MKTypography from "../../../../../components/MKTypography";
import "../../PrincipalsMessage/index.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Information() {
  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={4}>
            <img src={principalImg} alt="principal" />
          </Grid>
          <Grid item xs={12} lg={8}>
            <p className="principal-message">
              Greetings from Sahodaran Ayyappan Memorial College of Education, Poothotta. The
              institution was named after Sahodaran Ayyappan, who fought against the Social evils
              and superstitions which were prevalent during his life period. Moreover, he was an
              ardent disciple of Sree Narayana Guru who was a pungent force behind humanity and
              humanitarian values which laid emphasis on “Oru Jathi Oru Matham Oru Daivam
              Manushyanu”. The institution was started during the year 1994-95 with the under
              graduate programme of Education B.Ed. and it was upgraded to the P.G programme of
              Education (M.Ed.) during the year 2006-07. The institution is affiliated to Mahatma
              Gandhi University, Kottayam. The Sahodaran Ayyappan Memorial College of Education is approved and recognised
              by N.C.T.E. For the first time in Kerala, it was accredited by National Assessment and
              Accreditation Council (NAAC) with ‘A’ grade, in the self-financing sector. The
              Management (S.N.D.P Yogam branch, Poothotta) is always supportive for the well-being of
              the institution in every respect. Well qualified Teaching faculty and well equipped
              infra- structural facilities, including a Computer lab and Library which maintains
              high quality books are added attractions of the institution. Moreover, along with
              Academic enhancement, Sahodaran Ayyappan Memorial College of Education gives focus to the recreational talents of the
              student-trainees.
            </p>
            <div className=" row d-flex justify-content-end">
              <div className="col-12 col-lg-3" style={{textTransform : "none"}}>
                <h6> With warm regards</h6>
                <h5>Dr.K.P Anilkumar</h5>
                <h6>Principal</h6>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
