import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import TopMenu from "../../../../components/TopMenu/index";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../../../routes";
import footerRoutes from "../../../../footer.routes";

// Image
import bgInner from "../../../../assets/images/cover-1.webp";
import snlc from "../../../../assets/images/sister-concerns/snlc.jpg";
import kpmhs from "../../../../assets/images/sister-concerns/kpmhs.jpg";
import ssc from "../../../../assets/images/sister-concerns/ssc.jpg";
import snps from "../../../../assets/images/sister-concerns/snps.jpg";
import kpmhss from "../../../../assets/images/sister-concerns/kpmhss.jpg";
import sniti from "../../../../assets/images/sister-concerns/sniti.jpg";

function SisterConcern() {
  const [complaint, setComplaint] = useState([10]);

  const handleChange = (event) => {
    setComplaint(event.target.value);
  };
  return (
    <>
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgInner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          mt={-6}
          mb={1}
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Sister Concerns{" "}
        </MKTypography>
      </MKBox>
      <Container sx={{ mt: 6, mb: 6 }}>
        <Stack direction="column" spacing={2}>
          <Card sx={{ minWidth: 345, p: 5 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={3}>
                <img src={kpmhs} width="250" />
              </Grid>
              <Grid item xs={12} lg={9}>
                <Typography gutterBottom variant="h3" component="div">
                  Kshethra Pravesana Memorial High School, Poothotta
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  K.P.M High School, Poothotta was established in 1939 in memory of Temple Entry
                  Proclamation. Upgraded as High School in 1962. Accommodates 1400 students. K.P.M
                  VHSE was started in the year 1992. We offer a course on Medical Lab Technician
                  (MLT).
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ minWidth: 345, p: 5 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={3}>
                <img src={ssc} width="250" />
              </Grid>
              <Grid item item xs={12} lg={9}>
                <Typography gutterBottom variant="h3" component="div">
                  Sree Narayana Public School (Senior Secondary) , Poothotta
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sree Narayana Public School was established in the year 1994 under the management
                  of SNDP Yogam Branch No. 1103, Poothotta. It is affiliated to CBSE, with
                  affiliation No. 930154. In 2005 it became Senior Secondary. The school is named
                  after Sree Narayana Guru, who emphasized the need to get enlightened by education,
                  to get organized to march forward and to get enriched by industrialization. His
                  exhortation “Attain enlightenment through education” is sincerely followed by the
                  management. More Details visit{" "}
                  <a href="https://snpspoothotta.org/">www.snpspoothotta.org/</a>
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ minWidth: 345, p: 5 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={3}>
                <img src={snlc} width="250" />
              </Grid>
              <Grid item item xs={12} lg={9}>
                <Typography gutterBottom variant="h3" component="div">
                  Sree Narayana Law College , Poothotta
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sree Narayana Law College is a newly established Law Institute, situated at
                  Poothotta, 20 Kms away from Kochi. The College is owned and managed by the SNDP
                  Yogam Br:No. 1103, Poothotta. It is a matter of immense pride that this management
                  is an organization having a large academic set up in the port city of Kerala,
                  thanks to the visionary leadership. At present the management has the following
                  institutions in the campus.More Details visit{" "}
                  <a href="https://snlcpoothotta.org/">https://snlcpoothotta.org/</a>
                  <Typography gutterBottom variant="h6" component="div">
                    Courses Offered
                  </Typography>
                  <ol>
                    <li>B.A LL.B (Honours) 5 Year Course</li>
                    <li>BBA LL.B (Honours) 5 Year Course</li>
                    <li>B.Com LL.B (Honours) 5 Year Course</li>
                    <li>LL.B 3 Year Course</li>
                    <li>LL.M (Criminal Law)2 Year Course</li>
                    <li>LL.M (Commercial Law)2 Year Course</li>
                  </ol>
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ minWidth: 345, p: 5 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={3}>
                <img src={snps} width="250" />
              </Grid>
              <Grid item item xs={12} lg={9}>
                <Typography gutterBottom variant="h3" component="div">
                  Swami Saswathikananda College , Poothotta
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Swamy Saswathikananda College has been established with the aim of imparting
                  non-conventional, job oriented courses to the students of central Kerala,
                  especially Ernakulam, Kottayam and Alappuzha districts. Strategically placed near
                  the Ernakulam - Kottayam Highway at Puthenkavu, Poothotta, it has already captured
                  the attention of the students and parents looking for an ideal centre of academic
                  excellence set in an atmosphere conducive for the promotion of higher education.
                  The college campus lies on the western side of the famous Puthenkavu Temple,
                  hugging the palm fringed, breath takingly beautiful Poothotta kayal. The striking
                  edifice building is surrounded by hundreds of coconut trees with their heads
                  bowing in the gentle breeze blowing across the backwaters.The college was
                  sanctioned by the Kerala Government and affiliated with the M.G University in the
                  year 2002. More Details visit{" "}
                  <a href="http://www.sscollege.org">www.sscollege.org</a>
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ minWidth: 345, p: 5 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={3}>
                <img src={kpmhss} width="250" />
              </Grid>
              <Grid item item xs={12} lg={9}>
                <Typography gutterBottom variant="h3" component="div">
                  Kshethra Pravesana Memorial Higher Secondary School , Poothotta
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  K.P.M Higher Secondary School, Poothotta was established in 1998. K.P.M High
                  School, Poothotta was established in 1939 in memory of Temple Entry Proclamation.
                  Upgraded as High School in 1962. Accommodates 500 students. K.P.M VHSE was started
                  in the year 1992.
                  <Typography gutterBottom variant="h6" component="div">
                    We offer a courses as follows :
                  </Typography>
                  <ol>
                    <li>Science Group A (Biology, Physics, Chemistry) </li>
                    <li>Science Group B (Computer Science, Mathematics, Physics, Chemistry )</li>
                    <li>
                      Commerce Group (Computer Application, Accountancy, Business Studies,
                      Economics)
                    </li>
                    <li>Humanities group (Political Science, Sociology, History, Economics)</li>
                  </ol>
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ minWidth: 345, p: 5 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={3}>
                <img src={sniti} width="250" />
              </Grid>
              <Grid item item xs={12} lg={9}>
                <Typography gutterBottom variant="h3" component="div">
                  Sree Narayana Industrial Training Institute , Poothotta
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  S.N Private ITI Poothotta is an industrial Training Institution under Managed by
                  S.N.D.P. Yogam Branch No. 1103. Poothotta. Established in 1984 has developed into
                  one of the best Industrial Training Centers of the state with excellent
                  infrastructure, state of art training aids and well experienced Instructors. The
                  set targets are achieved and almost all the trainees passed out from the institute
                  are completely positioned in one or another jobs. More Details visit{" "}
                  <a href="http://www.snitipoothotta.org/">http://www.snitipoothotta.org/</a>
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Stack>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SisterConcern;
