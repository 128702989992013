import { gql } from "@apollo/client";

export const GALLERIES = gql`
  query GetGalleries($filterInput: FilterGalleryInput!) {
    getGalleries(filterInput: $filterInput) {
      totalCount
      galleries {
        id
        title
        thumbnail
        created_by
        status
        galleryImages {
          id
          img_link
          galleryId
        }
      }
    }
  }
`;

export const GALLERY_BY_ID = gql`
  query GetGalleryById($getGalleryByIdId: Int!) {
    getGalleryById(id: $getGalleryByIdId) {
      id
      title
      thumbnail
      image
      created_by
      status
    }
  }
`;
