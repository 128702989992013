import { gql } from "@apollo/client";

export const ACTIVITY = gql`
  query Activities($activitiesFilter: ActivityFilterInput!) {
    activities(activitiesFilter: $activitiesFilter) {
      totalCount
      activities {
        title
        status
        menutypeId
        image
        id
        description
        user {
          id
          name
        }
      }
    }
  }
`;
export const GET_ACTIVITYSUBMENU = gql`
  query GetSubmenuList {
    getSubmenuList {
      id
      title
      slug
      sortOrder
      status
    }
  }
`;

export const GET_ACTIVITYSUBMENUBYID = gql`
  query OneActivityBymenu($activityInput: ContentActivityInput!) {
    oneActivityBymenu(activityInput: $activityInput) {
      id
      description
      menutypeId
      title
      image
      status
      createdAt
      createdBy
      activitygallery {
        id
        img_link
        activityId
      }
    }
  }
`;
