// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";
import patron from "../../../../../assets/images/sahodaran-ayyappan.jpg";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: "linear-gradient(180deg, #FCC907 -1.52%, rgba(252, 201, 7, 0) 120.52%)",
//   ...theme.typography.body2,
//   padding: theme.spacing(4),
//   textAlign: "center",
//   color: theme.palette.text.primary,
//   marginBottom: 25,
// }));
const Item = styled("div")({
  color: "darkslategray",
  background: "linear-gradient(235deg, #FCC907 -1.52%, rgba(252, 201, 7, 0) 120.52%);",
  padding: 20,
  borderRadius: 4,
  cursor: "pointer",
  marginBottom: 25,
});
function Information() {
  return (
    <>
      <MKBox component="section" py={5}>
        <Container>
          <div className="row">
            <div className="content">
              <img src={patron} className="img-fluid left-side" alt="" />
              <p className="body-text">
                Sahodaran Ayyappan born on August 22<sup>nd</sup>, 1889 in Cherai, in Ernakulum. Sahodaran
                Ayyapan is a social reformer, revolutionary, poet, rationalist, an irreproachable
                administrator and a legislator who changed the face of history in Kerala. Ayyappan
                vociferously voiced about the rights of the marginalised sections of the society.
                Captivated by the philosophy of Sree Narayana Guru, Ayyappan became his disciple. He
                founded Sahodara Sangam, to facilitate the young members of lower caste communities
                to participate in movements initiating social change. He is noted for the great
                strides taken to thwart the caste system prevalent in Kerala. He spearheaded the
                Misra Bhojanam movement that can be translated as inter-dining of upper castes and
                lower castes which was a highly revolutionary activity that was condemned by not
                just the upper-class elites but also certain reformists. Ayyappan viewed
                inter-dining as a means to achieve the destruction of caste system. This
                inter-dining earned him the name of ‘Pulayan Ayyappan’. He also coined the slogan
                Jati Venda, Matam Venda, Deivam Venda Manushyanu (No caste, No religion, No God for
                Mankind).He started a journal titled ‘Sahodaran’ to promote renaissance in Kerala.
                It was primarily aimed to diffuse the ideas of Sahodara Sangam among the people of
                Kerala.He wrote extensively on the role of education in uplifting the downtrodden. He
                was elected into the Kochi Assembly in 1928. He was made a minister for Public Works
                in 1947. After the unification of Travancore and Kochi in 1949, he became a minister
                in the Thirukochi State. Sahodaran Ayyappan made history by resigning from his post
                as minister when the government he was part of decided to lay off employees in the
                lower rungs citing austerity measures. His extraordinary foresight and meticulous
                planning reflects in the many projects implemented whilst serving as the minister.
                He is still considered one among the few upstanding members in the political milieu.
                After his retirement from active political life, Sahodaran Ayyappan dedicated more on
                social welfare measures. He founded the ‘Sree Narayana Sevika Samajam which seeks to
                give a home to those who would otherwise have none. He was married to Parvathy
                Ayyappan and is survived by daughter Aisha Gopalakrishnan. He breathed his last on
                March 6, 1968. His death marked the end of an era and the legacy left behind by this
                stalwart will remain etched in the annals of Kerala’s socio-cultural renaissance
                history.
              </p>
              <h3 className="sam_heading"> <span style={{fontSize: '1.400rem'}}> SREE NARAYANA EDUCATIONAL INSTITUTIONS:</span>  A BRIEF HISTORY </h3>
              <p className="body-text">
              Sree Narayana Educational Complex is situated in the Tripunithura-Vaikom road,about 25kms south from Ernakulam town. Poothotta is blessed with the footprints of Jagath Guru Sree Narayana Guru, a great sage, and social reformerwho has put ardent efforts to set free humanity from the bondage of social oppression,during the time when Keralites were segregated in to different strata ,of which the majority were labelled the ‘untouhcables’ as they were born in a minority caste group. The efforts by Sree Narayana Guru and other spiritual leaders made vibrations in the minds many people which led to revolutionary changes in the approach of those ‘superiors’ over the untouchables.
              </p>
              <p className="body-text">
                            Guru firmly believed that the one and only tool to lead the humanity towards social
              reformation is by providing education.In order to uplift the downtrodden sections of the
              scoeciety to the main stream,he founded several temples in Kerala and a few on the west coast
              of Karnataka. In those days the temples were governed by orthodox theocrats were in
              accessible to most of the working class people. Even though the temples were barred to the so
              called “low caste “people their offering in money and kind were always accepted. Guru strongly
              believed that such shameless exploitation of the poor by the caste superiors could be met by
              founding ‘counter temples ‘ which were open to all.
              </p>
              <p className="body-text">
                            Narayana Guru was very particular that the new temples he founded were all spots of
              great scenic beauty. From the first experiment of founding a Siva temple at Aruvippuram,in
              Thiruvanathapuram district, he came to know how temples could become instrumental in
              changing the life of people. It might be the reason to select Poothotta, a picturesque village,
              encompassed with spiritual air blooming from different sources: such as Diamper Synod(
              Udayamperur )Sheik Fareedudheen Ralliyallahu Anhu Makhbabara,Kanjiramattom,;Vaikom
              Mahadeva temple, Sree Poornathrayesa temple Tripunithura. Of all the concsecrations done by
              Guru, Poothotta at Puthenkav Sree Narayana Vallabha temple is the third one in the year
              1893.After founding the temple at Puthenkavu, Guru ignited the minds of local villagers and
              proclaimed that “Let this place be enriched with Goddess of Education (Saraswathi Mutt) that
              leads to its sublimity “. Under the inspiring words of Guru, the villagers with the support and
              efforts of educated dignitaries and spiritual animators established educational institutions one
              after another. And thus Poothotta gradually entered in to realms of educational scenario in
              Kerala. At present there exists seven well established educational institutions which stand as
              the seven pillars of excellence. <b>Sahodaran Ayyappan Memorial College of Education </b>is one of
              the reverberating pillars. The other sister concerns are <b>Kshetha Pravesana Memorial higher
              Secondary School and VHSS, SN ITI, Sree Narayana Public School (CBSE Syllabus-Senior
              secondary), Swamy Saswathikanada Arts and Science College, and Sree Narayana Law
              College.</b> All these institutions are owned and managed by SNDP Yogam branch No 1103,
              Poothotta.
              </p>
              <p className="body-text">
                            SAM College of education, the flagship institution of SNDP yogam, has shown its
              presence during the short period by imparting quality teacher Education. S A M College now
              becomes the model for those who seek quality teacher education with all the postmodern
              pedagogic strategies and technologies. The college was established in the year 1995 in the
              name of Sahodaran Ayyappan,the illustrious disciple of Guru and nationalist educator. From its
              modest beginning the institution has grown steadily for the last 27years imparting Bachelor of
              Education course under eight optional subject viz: Malayalam,Hindi,English,Mathematics,
              Physicalscience, NaturalScience, Socialscience and Commerce.The College is affiliated to MG
              University, Kottayam and recognized by NCTE. The college got accredited with A grade in the
              year 2004. We are proud to announce that our college is the first institution in India to be
              accredited with A grade under self-financing sector.
              </p>
            </div>
          </div>
        </Container>
      </MKBox>
    </>
  );
}

export default Information;
