import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "../src/assets/style.css";
// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import ContactUs from "pages/LandingPages/ContactUs";
import Institution from "pages/LandingPages/About/Institution";
import Infrastructure from "pages/LandingPages/About/Infrastructure";
import Enquiry from "pages/LandingPages/Admission/Enquiry";
import Procedure from "pages/LandingPages/Admission/Procedure";
import Fee from "pages/LandingPages/Admission/Fee";
import Complaints from "pages/LandingPages/Complaints";
import Gallery from "pages/LandingPages/Gallery";
import Activities from "pages/LandingPages/Activities";
import Events from "pages/LandingPages/Events/Events";
import News from "pages/LandingPages/Events/News";
import SisterConcern from "pages/LandingPages/About/SisterConcern";
import Mandatorydisclosure from "pages/LandingPages/Mandatorydisclosure";
import Downloads from "pages/LandingPages/Downloads";

// Material Kit 2 React routes
import routes from "routes";
import AboutUs from "pages/LandingPages/About/AboutUs";
import AboutTypes from "./pages/LandingPages/About/AboutTypes/index";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/home" element={<Presentation />} />
        <Route path="/about-us/institution" element={<Institution />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us/infrastructure" element={<Infrastructure />} />
        <Route path="/about-us/sister-concern" element={<SisterConcern />} />
        <Route path="/admission/enquiry" element={<Enquiry />} />
        <Route path="/admission/procedure" element={<Procedure />} />
        <Route path="/admission/fee" element={<Fee />} />
        <Route path="/complaints" element={<Complaints />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/events" element={<Events />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/news" element={<News />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/about-us/*" element={<AboutTypes />} />
        <Route path="/activities/*" element={<Activities />} />
        <Route path="/mandatory-disclosure/*" element={<Mandatorydisclosure />} />
      </Routes>
    </ThemeProvider>
  );
}
