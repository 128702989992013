// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { NEWS } from "../../../../../graphql/query/news";
import loader from "assets/images/loader.gif";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";
import "../sections/index.css";
import noData from "../../../../../assets/images/no_data_found.png";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import { height } from "@mui/system";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 25,
}));

function Information() {
  const [offset] = React.useState(0);
  const [newsDatas, setNewsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(NEWS, {
    variables: {
      newsFilter: {
        status: true,
        limit: 4,
        offset,
        orderDirection: "desc",
        orderColumn: "id",
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setNewsDatas([]);
      } else {
        setNewsDatas(data.news.news);
      }
    }
  }, [data, loading, error]);
  return (
    <MKBox component="section" py={5}>
      {loading ? (
        <div className="row d-flex justify-content-center">
          <img alt="loader" src={loader} className="loader" />
        </div>
      ) : (
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Grid container justifyContent="flex-start">
                {newsDatas.length ? (
                  <Grid item xs={12}>
                    {newsDatas.map((item) => (
                      <Item key={item.title} sx={{ padding: 0, marginBottom: 5 }}>
                        <MKBox mb={5}>
                          <Grid container spacing={3} alignItems="center" py={2} pb={5}>
                            <Grid item xs={12} lg={4}>
                              <img className="eventImg" src={item.image} />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                              <DefaultInfoCard title={item.title} description={item.description} />
                            </Grid>
                          </Grid>
                        </MKBox>
                      </Item>
                    ))}
                  </Grid>
                ) : (
                  // <h3>No Data to Display</h3>
                  // <MKBox>
                  <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={3}>
                      <img src={noData} width="300" />
                    </Grid>
                  </Grid>
                  // </MKBox>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default Information;
