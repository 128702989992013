// @mui material components
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
// Material Kit 2 React components
import EmailIcon from "@mui/icons-material/Email";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";

// Routes

// Images
import logoImage from "assets/images/sam_icon.png";
import "../TopMenu/index.css";

const TopmenuContact = styled("div")({
  color: "rgba(10, 10, 10, 0.8)",
  fontWeight: "400",
  fontSize: "16px",
});

function TopMenu() {
  return (
    <>
      <Container sx={{ display: { xs: "none", lg: "block" } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={9} sx={{ paddingBottom: "10px" }}>
            <Link to="/">
              <img src={logoImage} width="400" className="img-fluid" alt="Logo" cursor="pointer" />
            </Link>
          </Grid>
          <Grid item lg={3}>
            <TopmenuContact>
              <EmailIcon />
              &nbsp;samcoed@gmail.com
              <br />
              <PhoneForwardedIcon />
              &nbsp;0484-2791218
            </TopmenuContact>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TopMenu;
