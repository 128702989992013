import { gql } from "@apollo/client";

export const ACTIVITYMENU = gql`
  query activitysubmenus($activitysubmenuFilter: ActivitysubmenuFilterInput!) {
    activitysubmenus(activitysubmenuFilter: $activitysubmenuFilter) {
      totalCount
      activitysubmenu {
        id
        title
        sortOrder
        slug
        status
      }
    }
  }
`;
export const Activitysubmenu = gql`
  query GetSubmenuList($status: Boolean!) {
    getSubmenuList(status: $status) {
      id
      title
      slug
      sortOrder
      status
    }
  }
`;
