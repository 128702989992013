import { gql } from "@apollo/client";

export const Types = gql`
  query SubMenus($subMenuFilter: SubmenuFilterInput!) {
    subMenus(subMenuFilter: $subMenuFilter) {
      totalCount
      aboutSubmenus {
        id
        title
        slug
        sortOrder
        status
        createdAt
        user {
          id
          name
        }
      }
    }
  }
`;
export const Aboutsubmenu = gql`
  query GetAboutSubmenuList($status: Boolean!) {
    getAboutSubmenuList(status: $status) {
      slug
      title
      id
      sortOrder
      status
    }
  }
`;
