// Pages
import Presentation from "layouts/pages/presentation";
import ContactUs from "pages/LandingPages/ContactUs";
import Institution from "pages/LandingPages/About/Institution";
import Infrastructure from "pages/LandingPages/About/Infrastructure";
import PrincipalsMessage from "pages/LandingPages/About/PrincipalsMessage";
import Academics from "pages/LandingPages/Academics/Academics";
import Enquiry from "pages/LandingPages/Admission/Enquiry";
import Procedure from "pages/LandingPages/Admission/Procedure";
import Fee from "pages/LandingPages/Admission/Fee";
import Complaints from "pages/LandingPages/Complaints/index";
import Gallery from "pages/LandingPages/Gallery";
import Activities from "pages/LandingPages/Activities";
import AboutTypes from "pages/LandingPages/About/AboutTypes";
import SalientFeatures from "pages/LandingPages/About/SalientFeatures";
import Events from "pages/LandingPages/Events/Events";
import Downloads from "pages/LandingPages/Downloads/Downloads";
import News from "pages/LandingPages/Events/News";
import SisterConcern from "pages/LandingPages/About/SisterConcern";
import Mandatorydisclosure from "pages/LandingPages/Mandatorydisclosure";
// import Downloads from "pages/LandingPages/Downloads";

const routes = [
  {
    name: "Home",
    route: "/",
    columns: 1,
    rowsPerColumn: 2,
    component: <Presentation />,
  },
  {
    name: "Gallery",
    columns: 1,
    rowsPerColumn: 2,
    route: "/gallery",
    component: <Gallery />,
  },
  {
    name: "About",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "The Institution",
            route: "/about-us/institution",
            component: <Institution />,
          },
          {
            name: "Principal’s Message",
            route: "/about-us/principals-message",
            component: <PrincipalsMessage />,
          },
          {
            name: "Salient Features",
            route: "/about-us/salient-features",
            component: <SalientFeatures />,
          },
          {
            name: "Infrastructure",
            route: "/about-us/infrastructure",
            component: <Infrastructure />,
          },
          {
            name: "Sister Concern",
            route: "/about-us/sister-concern",
            component: <SisterConcern />,
          },
        ],
      },
    ],
  },
  {
    name: "Academics",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "Bachelor of Education",
            route: "/academics",
            component: <Academics />,
          },
        ],
      },
    ],
  },
  {
    name: "Admission",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "Admission Enquiry",
            route: "/admission/enquiry",
            component: <Enquiry />,
          },
          {
            name: "Admission Procedure",
            route: "/admission/procedure",
            component: <Procedure />,
          },
          {
            name: "Fee Structure",
            route: "/admission/fee-structure",
            component: <Fee />,
          },
        ],
      },
    ],
  },
  {
    name: "Activities",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [],
      },
    ],
  },
  {
    name: "Events",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "Events",
            route: "/events",
            component: <Events />,
          },
          {
            name: "News",
            route: "/news",
            component: <News />,
          },
        ],
      },
    ],
  },
  {
    name: "Mandatory Disclosure",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [],
      },
    ],
  },
  {
    name: "Complaints",
    columns: 1,
    rowsPerColumn: 2,
    route: "/complaints",
    component: <Complaints />,
  },
  {
    // name: "Downloads",
    // columns: 1,
    // rowsPerColumn: 2,
    name: "Downloads",
    columns: 1,
    rowsPerColumn: 2,
    route: "/downloads",
    component: <Downloads />,
    // collapse: [
    //   {
    //     collapse: [
    //       {
    //         name: "Downloads",
    //         route: "/downloads",
    //         component: <Downloads />,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    name: "Contact Us",
    columns: 1,
    rowsPerColumn: 2,
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
