// @mui material components
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

// About Us page sections
import Information from "./sections/Information";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import TopMenu from "../../../../components/TopMenu";
import MKTypography from "../../../../components/MKTypography";

// Images
import bgInner from "../../../../assets/images/cover-1.webp";

// Routes
import footerRoutes from "../../../../footer.routes";
import routes from "../../../../routes";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../../examples/Footers/DefaultFooter";

const MainContainer = styled("div")({
  color: "#0A0A0A",
  backgroundColor: "#fff",
  padding: 1,
  textTransform: "uppercase",
  opacity: "0.8",
  fontWeight: "400",
  fontFamily: "inherit",
});

function AboutUs() {
  return (
    <>
      <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>

      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgInner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          mt={-6}
          mb={1}
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          About Us{" "}
        </MKTypography>
      </MKBox>
      <MainContainer>
        <Container
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            backgroundColor: "#fff",
          }}
        >
          <Information />
        </Container>
      </MainContainer>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
