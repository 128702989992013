import { gql } from '@apollo/client';

export const EVENTS = gql`
  query Events($eventFilter: EventFilterInput!) {
    events(eventFilter: $eventFilter) {
      totalCount
      events {
        id
        title
        description
        image
        link
        date
        status
        createdBy
      }
    }
  }
`;
