import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import TopMenu from "../../../components/TopMenu/index";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";
import { COMPLAINT } from "../../../graphql/mutation/complaint";

import { useMutation } from "@apollo/client";
import SnackbarAlert from "../../../common/SnackbarAlert";

// Image
import bgImage from "../../../assets/images/cover-1.webp";

function Complaints() {
  const [getName, setName] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getPhone, setPhone] = useState("");
  // const [complaint, setComplaint] = useState(null);

  const [getMessage, setMessage] = useState("");
  const [getNameError, setNameError] = useState("");
  const [getEmailError, setEmailError] = useState("");
  const [getPhoneError, setPhoneError] = useState("");
  // const [getOptionError, setOptionError] = useState("");
  const [getMessageError, setMessageError] = useState("");
  const [showSnackBarAlert, setShowSnackBarAlert] = useState({
    status: false,
    type: "success",
    message: null,
  });
  const [complaintForm] = useMutation(COMPLAINT, {
    onCompleted: () => {
      setShowSnackBarAlert({ status: true, type: "success", message: "Message sent" });
      window.location.reload(true);
    },
  });

  const handleSubmit = () => {
    event.preventDefault();
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (getName.length === 0) {
      setNameError("Name is Required");
    } else {
      setNameError("");
    }
    if (getEmail.length === 0) {
      setEmailError("Email is Required");
    } else {
      setEmailError("");
    }
    if (getEmail.length === 0) {
      setEmailError("Email is Required");
    } else if (pattern.test(getEmail) == false) {
      setEmailError("Enter valid email address");
    } else {
      setEmailError("");
      return;
    }

    if (getPhone.length === 0) {
      setPhoneError("Phone Number  is Required");
    } else if (getPhone.length !== 10) {
      setPhoneError("Enter Valid Phone Number");
      return;
    }
    complaintForm({
      variables: {
        complaintInput: {
          name: getName,
          email: getEmail,
          // option: complaint,
          phone: getPhone,
          message: getMessage,
        },
      },
    });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  // const handleChange = (event) => {
  //   setComplaint(event.target.value);
  // };
  return (
    <>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        setShowSnackBarAlert={setShowSnackBarAlert}
      />
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Complaints{" "}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={8}
          xl={8}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 10, sm: 9, md: 10 }}
            mb={{ xs: 10, sm: 9, md: 10 }}
            mx={3}
          >
            <MKBox bgColor="warning" coloredShadow="warning" borderRadius="lg" p={2} mx={2} mt={-3}>
              <MKTypography variant="h3" color="white">
                Complaints
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => handleNameChange(e)}
                    />
                    {getNameError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getNameError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      moz-appearance="none"
                      variant="standard"
                      label="Phone"
                      type="tel"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => handlePhoneChange(e)}
                    />
                    {getPhoneError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getPhoneError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => handleEmailChange(e)}
                    />
                    {getEmailError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getEmailError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Complaint</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ minWidth: "100%", height: 40 }}
                        value={complaint}
                        label="complaint"
                        onChange={handleChange}
                        variant="standard"
                      >
                        <MenuItem value={"null"}>Select</MenuItem>
                        <MenuItem value={"Caste based descrimination"}>
                          Caste based descrimination
                        </MenuItem>
                        <MenuItem value={"Other complaints"}>Other complaints</MenuItem>
                      </Select>
                    </FormControl>
                    {getOptionError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getOptionError}
                      </MKTypography>
                    ) : null}
                  </Grid> */}
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="How can we help you?"
                      // placeholder="Describe your complaint"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      onChange={(e) => handleMessageChange(e)}
                    />
                    {getMessageError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getMessageError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" color="warning" onClick={handleSubmit}>
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Complaints;
