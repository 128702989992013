import { gql } from "@apollo/client";

export const ABOUT = gql`
  query Abouts($aboutFilter: AboutFilterInput!) {
    abouts(aboutFilter: $aboutFilter) {
      totalCount
      abouts {
        id
        designation
        full_name
        notes
        image
        status
        createdBy
        user {
          name
        }
        aboutTypeId
      }
    }
  }
`;
export const GET_ABOUTSUBMENU = gql`
  query GetAboutSubmenuList {
    getAboutSubmenuList {
      id
      title
      slug
      sortOrder
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const GET_ABOUTSUBMENUBYID = gql`
  query GetAboutsubMenuByid($aboutInput: ContentAboutInput!) {
    getAboutsubMenuByid(aboutInput: $aboutInput) {
      id
      designation
      full_name
      notes
      image
      status
    }
  }
`;
