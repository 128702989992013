import { gql } from '@apollo/client';

export const NEWS = gql`
  query News($newsFilter: NewsFilterInput!) {
    news(newsFilter: $newsFilter) {
      news {
        status
        id
        title
        description
        image
        createdAt
        user {
          id
          name
        }
      }
      totalCount
    }
  }
`;
