import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useQuery } from "@apollo/client";
import { GET_ACTIVITYSUBMENUBYID } from "../../../graphql/query/activity";
import { withStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
// Material Kit 2 React components
import loader from "assets/images/loader.gif";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import TopMenu from "../../../components/TopMenu/index";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ExampleCard from "pages/Presentation/components/ExampleCard";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";
import { useLocation } from "react-router-dom";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// Image
import bgImage from "../../../assets/images/cover-2.webp";
import noData from "../../../assets/images/no_data_found.png";
const styles = (theme) => ({
  card: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});

function Activities() {
  const [activityDatas, setActivityDatas] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(0);
  const [activitygallery, setActivityGallery] = React.useState([]);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const routeArray = window.location.pathname.split("/");
  window.location.pathname.split("/");
  const name = routeArray[2];
  const headName = name.replaceAll("_", " ");
  const { loading, error, data, refetch } = useQuery(GET_ACTIVITYSUBMENUBYID, {
    variables: {
      activityInput: {
        menutypeId: parseFloat(routeArray[3]),
        status: true,
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setActivityDatas([]);
      } else {
        setActivityDatas(data.oneActivityBymenu);
      }
    }
  }, [data, loading, error]);

  const location = useLocation();
  React.useEffect(() => {
    const { pathname } = location;
    const splitArray = pathname.split("/");
    refetch({
      type: parseFloat(splitArray[2]),
      status: true,
    });
  }, [location.pathname]);

  const handleSubmit = (item, index) => {
    setIsOpen(index);
    setActivityGallery(Object.values(item.activitygallery).map((galImg) => galImg.img_link));
    // console.log(Object.values(item.galleryImages).map((galImg) => galImg.img_link));
  };

  return (
    <>
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <p style={{ textTransform: "capitalize" }}>{headName}</p>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      {loading ? (
        <div className="row d-flex justify-content-center">
          <img alt="loader" src={loader} className="loader" />
        </div>
      ) : (
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={12}
            xl={12}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            {activityDatas.length ? (
              <MKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={{ xs: 10, sm: 8, md: 10 }}
                mb={{ xs: 10, sm: 8, md: 10 }}
                mx={3}
                p={2}
              >
                <ImageList cols={4}>
                  {activityDatas.map((item) => (
                    <ImageListItem key={item.image}>
                      {/* <img
                    src={`${item.image}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={<span>by: {item.title}</span>}
                    position="below"
                  /> */}
                    </ImageListItem>
                  ))}
                </ImageList>

                <Container>
                  {activityDatas.map((gallery, index) => (
                    <Grid container spacing={3} sx={{ alignItems: "center" }}>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        onClick={() => handleSubmit(gallery, index + 1)}
                        key={gallery.id}
                      >
                        <ExampleCard
                          image={gallery.image}
                          name=""
                          display="grid"
                          minHeight="auto"
                          textAlign="center"
                        />
                      </Grid>

                      {!!isOpen && (
                        <Lightbox
                          mainSrc={activitygallery[photoIndex]}
                          nextSrc={activitygallery[(photoIndex + 1) % activitygallery.length]}
                          prevSrc={
                            activitygallery[
                              (photoIndex + activitygallery.length - 1) % activitygallery.length
                            ]
                          }
                          onCloseRequest={() => setIsOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + activitygallery.length - 1) % activitygallery.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % activitygallery.length)
                          }
                        />
                      )}
                      <Grid item xs={12} lg={8}>
                        {/* {activityDatas.map((item) => ( */}
                        <Grid key={gallery.title}>
                          <MKBox mb={5}>
                            <DefaultInfoCard
                              title={gallery.title}
                              description={gallery.description}
                            />
                            {/* <img src={item.image} /> */}
                          </MKBox>
                        </Grid>
                        {/* ))} */}
                      </Grid>
                    </Grid>
                  ))}
                </Container>
              </MKBox>
            ) : (
              <MKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={{ xs: 10, sm: 8, md: 10 }}
                mb={{ xs: 10, sm: 8, md: 10 }}
                mx={3}
              >
                {" "}
                {/* <h3>No data to display</h3> */}
                <MKBox sx={{ justifyContent: "center", textAlign: "center" }}>
                  <img src={noData} width="300" />
                </MKBox>
              </MKBox>
            )}
          </Grid>
        </Grid>
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Activities;
