// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MKTypography from "../../../../../components/MKTypography";

// Material Kit 2 React examples

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 25,
}));
function Information() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Bachelor of Education
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.dark"
                      sx={{ textTransform: "none", lineHeight: 2 }}
                    >
                      The duration of B.Ed programme will be two year with four semesters, having a
                      break after one year on April-May. Those students who have a minimum of 75%
                      average attendance for all the courses can register for the examination. The
                      student who have shortage of attendance should repeat the course along with
                      the next batch. Admission to the B.Ed course is according to the MG University
                      schedule. Admission in the merit seat for B.Ed course will be as per the
                      centralized allotment procedure of the Government of Kerala. 50% of the seats
                      will be filled by Management.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Record Of Attendance
                    </Typography>
                    <MKTypography variant="body2" color="text">
                      <ul>
                        <li>
                          Teachers will register the attendance of students at the beginning of each
                          period.{" "}
                        </li>
                        <li>
                          {" "}
                          A student who is not in the class when attendance is taken shall be marked
                          absent. It will however be permissible for the teacher to mark present a
                          student coming late, on the merit of the case.{" "}
                        </li>
                        <li>
                          {" "}
                          Absence from the class for a period will be considered absent for the
                          forenoon or the afternoon session as the case may be.{" "}
                        </li>
                        <li>
                          {" "}
                          The Annual Certificate of attendance and Progress required for admission
                          to the University Examination will not ordinarily be issued unless (i) a
                          student has the minimum of 75% of attendance prescribed by the University
                          (ii) the student has completed the course of instructions to the
                          satisfaction of the authorities and (iii) his or her conduct throughout
                          has been satisfactory.{" "}
                        </li>
                      </ul>
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Leave Rules
                    </Typography>
                    <MKTypography variant="body2" color="text">
                      <ul>
                        <li>
                          Application for leave should ordinarily reach the principal a day before
                          it is required. In case of sudden illness or other unforeseen reason
                          application for leave should reach him before noon of the day
                        </li>
                        <li>
                          A student required leave for a period or portion thereof must apply for it
                          to the Lecturers concerned before the class begins.
                        </li>
                        <li>
                          A student in need of leaving the class in one session of a day must apply
                          for permission in writing to
                        </li>
                        <li>Leave should not be taken in combination with holidays.</li>
                        <li>
                          {" "}
                          Absence without leave shall be liable to pay a fine to be decided by the
                          principal.{" "}
                        </li>
                        <li>
                          {" "}
                          Students who are absent without leave for two or more days consecutively
                          shall submit before entering the class a written explanation for their
                          absence to the principal.{" "}
                        </li>
                        <li>
                          {" "}
                          Leave of absence should not be availed of as a matter of right. Genuine
                          and acceptable reasons should be furnished.{" "}
                        </li>
                        <li>
                          {" "}
                          No Student will be granted leave for more than 10 days in an academic
                          year. When leave is applied for owing to sickness production of proper
                          Medical certificate may be required.{" "}
                        </li>
                        <li>
                          {" "}
                          If a student absents himself/herself for five consecutive working days
                          without applying for leave, his/her name will ordinarily be removed from
                          the rolls of the college.{" "}
                        </li>
                      </ul>
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Library Rules
                    </Typography>
                    <MKTypography variant="body2" color="text">
                      <ul>
                        <li>
                          {" "}
                          The Library and reading room shall be open from 8.30 am to 5.30 p.m. on
                          all working days.{" "}
                        </li>
                        <li>
                          {" "}
                          Strict silence, discipline and decorum must be observed in the library and
                          the reading room.{" "}
                        </li>
                        <li>
                          {" "}
                          No student shall damage, write or make any mark on or inside any book or
                          journal belonging to college.{" "}
                        </li>
                        <li> Reference books shall not be lent out to students. </li>
                        <li>
                          {" "}
                          Students should apply for books using their library cards between 9 a.m.
                          and 12 noon and the books applied for will be issued between 1 p.m. and 5
                          p.m. on the same day.{" "}
                        </li>
                        <li>
                          {" "}
                          Two books will be issued at a time from the library in the usual course.{" "}
                        </li>
                        <li>
                          {" "}
                          The reader shall be held responsible for any damage done to book or any
                          Library-property. Before the books are taken out each student should
                          satisfy himself/ herself that the books lent out to him/her are in good
                          condition and if not, the fact should be brought to notice of the
                          Librarian.{" "}
                        </li>
                        <li>
                          {" "}
                          If a book or any Library property is damaged or lost the reader will have
                          to replace it or pay three times the cost of the book etc. A greater
                          penalty may be imposed according to the gravity of the offence.{" "}
                        </li>
                        <li> Books are to be returned in the forenoon. </li>
                        <li>
                          {" "}
                          Books should not be retained for more than twelve days. They must be
                          returned on the due date even if the borrowers are on leave.{" "}
                        </li>
                        <li> Members are not allowed to sublend the books of the Library. </li>
                        <li>
                          {" "}
                          All the books and other property of the college in possession of the
                          student should be returned before the final examination.{" "}
                        </li>
                        <li>
                          {" "}
                          Readers desirous of using the reference section shall enter their names in
                          register provided for temporary reading and return the volume immediately
                          after use on the same day.{" "}
                        </li>
                      </ul>
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Library Works
                    </Typography>
                    <MKTypography variant="body2" color="text">
                      <ul>
                        <li>
                          Every student is expected to make the best use of the college Library and
                          the reading room and to maintain a record of his work.
                        </li>
                        <li>
                          Students are instructed to be in library during the working hours if they
                          have no other allotted work.
                        </li>
                        <li>
                          Special library hours are provided in the time-table with a view to
                          intensify the reading habits of students.
                        </li>
                        <li>
                          Practical record of the Library work shall be submitted to the lecturer
                          concerned for correction and evaluation.{" "}
                        </li>
                      </ul>
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Internal Evaluation
                    </Typography>
                    <MKTypography variant="body2" color="text">
                      {" "}
                      Internal evaluation shall be done on the bases of the attendance,
                      assignment/project/survey/seminar and test paper conducted by the college.
                      There will be an examination conducted by university at the end of each
                      semester.{" "}
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ my: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="div"
                      sx={{ textTransform: "none" }}
                    >
                      Code Of Behaviour
                    </Typography>
                    <MKTypography variant="body2" color="text">
                      <ul>
                        <li>
                          {" "}
                          Students who happen to have no allotted work should not loiter in the
                          college premises during working hours. They are expected to be in the
                          Library during the hours when they have no work according to the time
                          table.{" "}
                        </li>
                        <li>
                          {" "}
                          Students are forbidden from attending or organizing any meeting or
                          collecting money for any such purpose without the previous approval by the
                          Principal. They should not circulate among the students any notice or
                          petition or paste it up anywhere in the college premises without the
                          written permission of the Principal.{" "}
                        </li>
                        <li>
                          {" "}
                          Trainees should always be respectful to the staff of the institution, and
                          should show themselves love of decency and decorum.{" "}
                        </li>
                        <li>
                          {" "}
                          Smoking or any other misbehavior in the college premises and general
                          neglect of studies and other duties will be considered as serious offense.{" "}
                        </li>
                        <li>
                          {" "}
                          For neglect of work or for any misconduct of a serious nature a student is
                          liable to be declared unfit for teaching profession and dismissed from the
                          institution.{" "}
                        </li>
                        <li> Use of mobile phone in the campus is strictly prohibited. </li>
                      </ul>
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
