// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox/index";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 20,
}));
function Information() {
  return (
    <MKBox component="section" py={4}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      title="Parent-Teacher Association"
                      description="The executive committee of the PTA is elected from the General Body meeting held at the beginning of each academic year. Executive committee meets twice every academic year."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      title="Alumni Association"
                      description="The college has an active Alumni Association. They works regularly for the well being of the institution."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Placement Cell"
                      description="A placement cell functions in our college with an aim to provide job opportunities to our students."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Co-Curricular Activities"
                      description="The college provides opportunities for cultural and physical activities for a balanced development of teacher personality. For nurturing the sports talent of teacher trainees the college organizes an annual throw ball tournament for Sahodaran Ayyappan Memorial Ever Rolling Trophy with the participation of all B.Ed. Colleges under M.G. University Kottayam. The College organise an intercollegiate art festival SAM FEST and a quiz competition for all B.Ed. Colleges under M.G. University, Kottayam.

                      Besides these major intercollegiate competition college organizes a variety of cultural, literary and sports activities."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="College Union"
                      description="The college union members are elected by the students as per the direction and instructions of Mahatma Gandhi University. The Union members are
                      <ul>
                      <li>Chairman</li>
                      <li>Vice-Chairperson</li>
                      <li>General Secretary</li>
                      <li>Arts Club secretary</li>
                      <li>Magazine Editor</li>
                      <li>University Union Councilor</li>
                      <li>One class representative from each optional</li>
                      </ul>
                      The College union is expected to function in a democratic manner to co-ordinate student activities of the year. The concerned teachers give necessary guidance for the efficient functioning of the union."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Library Works"
                      description="Every student is expected to make the best use of the college Library and the reading room and to maintain a record of his work.

                      Students are instructed to be in library during the working hours if they have no other allotted work. Special library hours are provided in the time-table with a view to intensify the reading habits of students."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Film Club"
                      description="An active Film Club is functioning in the institution."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Clubs and Activities"
                      description="Various clubs are organized every year to give emphasis to the practical aspects of knowledge acquired. These clubs organize community welfare programs and conduct competitions , Seminars, Symposium and workshops. The clubs are
                      <ul>
                        <li>Language Club</li>
                        <li>Mathematics Club</li>
                        <li>Social Science Club</li>
                        <li>Science Club</li>
                        <li>Commerce Club</li>
                        <li>Social Service League</li>
                        <li>ELA(Eco Local Action) Club</li>
                        <li>Sports Club</li>
                        <li>Quiz Club</li>
                        <li>Film Club</li>
                      </ul>
                     "
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Women Cell"
                      description="A women cell functions in the college with an aim to conscientise the women folk about their right and abilities and also to empower them in their march towards future life."
                    />
                  </MKBox>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      title="Student Support Services"
                      description="Guidance and counseling cell functions in an effective manner in the college. The teacher - in charge of the cell functions as the counselor. The objectives of the cell are:
                      <ul>
                      <li>To identify the problems of student teachers</li>
                      <li>To provide them opportunity to share their problems</li>
                      </ul>"
                    />
                  </MKBox>
                </Item>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
