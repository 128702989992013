import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useQuery } from "@apollo/client";
import { GET_ABOUTSUBMENUBYID } from "../../../../graphql/query/about";
// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import TopMenu from "../../../../components/TopMenu/index";
import loader from "assets/images/loader.gif";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../../../routes";
import footerRoutes from "../../../../footer.routes";
import { useLocation } from "react-router-dom";

// Image
import bgImage from "../../../../assets/images/cover-1.webp";
import noData from "../../../../assets/images/no_data_found.png";
import { fontSize } from "@mui/system";

function About() {
  const [offset] = React.useState(0);
  const [aboutDatas, setAboutDatas] = React.useState([]);
  console.log(window.location.pathname.split("/"));
  const routeArray = window.location.pathname.split("/");
  const name = routeArray[2];
  const headName = name.replaceAll("_", " ");
  const { loading, error, data, refetch } = useQuery(GET_ABOUTSUBMENUBYID, {
    variables: {
      aboutInput: {
        aboutTypeId: parseFloat(routeArray[3]),
        status: true,
      },
    },
  });

  const location = useLocation();
  React.useEffect(() => {
    const { pathname } = location;
    const splitArray = pathname.split("/");
    refetch({
      type: parseFloat(splitArray[2]),
      status: true,
    });
  }, [location.pathname]);

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setAboutDatas([]);
      } else {
        setAboutDatas(data.getAboutsubMenuByid);
      }
    }
  }, [data, loading, error]);

  return (
    <>
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <p style={{ textTransform: "capitalize" }}>{headName}</p>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      {loading ? (
        <div className="row d-flex justify-content-center">
          <img alt="loader" src={loader} className="loader" />
        </div>
      ) : (
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={12}
            xl={12}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 10, sm: 8, md: 10 }}
              mb={{ xs: 10, sm: 8, md: 10 }}
              mx={3}
            >
              {aboutDatas.length ? (
                <ImageList cols={5}>
                  {aboutDatas.map((item, index) => (
                    <div key={index}>
                      <ImageListItem key={item.image} sx={{ margin: "10px" }}>
                        <img
                          src={`${item.image}?w=248&fit=crop&auto=format`}
                          srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.title}
                          loading="lazy"
                          className="staffImg"
                        />
                        <ImageListItemBar
                          sx={{ textAlign: "center" }}
                          title={<span> {item.full_name}</span>}
                          subtitle={<span>{item.designation}</span>}
                          actionIcon={
                            <IconButton
                              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                              aria-label={`info about ${item.title}`}
                            >
                              {/* <InfoIcon /> */}
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                      <div
                        style={{ justifyContent: "center", textAlign: "center", fontSize: "13px", color: "rgb(114, 109, 109)" }}
                      >
                        {item.notes}
                      </div>
                    </div>
                  ))}
                </ImageList>
              ) : (
                // <h3>No Data to Display</h3>
                <MKBox sx={{ justifyContent: "center", textAlign: "center" }}>
                  <img src={noData} width="300" />
                </MKBox>
              )}
            </MKBox>
          </Grid>
        </Grid>
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default About;
