// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";
// Material Kit 2 React components
import loader from "assets/images/loader.gif";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TopMenu from "../../../components/TopMenu/index";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import ExampleCard from "pages/Presentation/components/ExampleCard";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "../../../assets/images/cover-3.webp";
import noData from "../../../assets/images/no_data_found.png";
import "../Gallery/index.css";

import { useQuery } from "@apollo/client";
import * as React from "react";
import { GALLERIES } from "graphql/query/gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const styles = (theme) => ({
  card: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});
function Gallery() {
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [totalCount, setTotalCount] = React.useState(0);
  const [galleryDatas, setGalleryDatas] = React.useState([]);
  const [galleryImages, setGalleryImages] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(0);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const { loading, error, data } = useQuery(GALLERIES, {
    variables: {
      filterInput: {
        sortColumn: "id",
        sortOrder: "desc",
        offset,
        limit: rowsPerPage,
        status: true,
      },
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setGalleryDatas([]);
      } else {
        setGalleryDatas(data.getGalleries.galleries);
        setTotalCount(data.getGalleries.totalCount);
      }
    }
  }, [data, loading, error]);

  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setOffset(0);
  };
  const handleSubmit = (item, index) => {
    setIsOpen(index);
    if (item.galleryImages.length === 0) {
      galleryImages.push(item.thumbnail);
      setGalleryImages(galleryImages);
    } else {
      setGalleryImages(Object.values(item.galleryImages).map((galImg) => galImg.img_link));
    }
  };
  const modalCloseEvent = () => {
    setPhotoIndex(0);
    setGalleryImages([]);
    setIsOpen(false);
  };
  return (
    <>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      /> */}
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>

      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <MKTypography
          variant="h1"
          color="white"
          mt={-6}
          mb={1}
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Gallery{" "}
        </MKTypography>
      </MKBox>
      {loading ? (
        <div className="row d-flex justify-content-center">
          <img alt="loader" src={loader} className="loader" />
        </div>
      ) : (
        <MKBox component="section" py={8}>
          <Container>
            {galleryDatas.length ? (
              <Grid container spacing={3}>
                {galleryDatas.map((gallery, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={3}
                    onClick={() => handleSubmit(gallery, index + 1)}
                    key={gallery.id}
                  >
                    <ExampleCard
                      image={gallery.thumbnail}
                      name={gallery.title}
                      display="grid"
                      minHeight="auto"
                      textAlign="center"
                      className="gallery-thumb"
                    />
                  </Grid>
                ))}
                {!!isOpen && (
                  <Lightbox
                    mainSrc={galleryImages[photoIndex]}
                    nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
                    prevSrc={
                      galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]
                    }
                    onCloseRequest={() => modalCloseEvent()}
                    onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)
                    }
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryImages.length)}
                  />
                )}
              </Grid>
            ) : (
              // <h3>No Data to Display</h3>
              <MKBox sx={{ justifyContent: "center", textAlign: "center" }}>
                <img src={noData} width="300" />
              </MKBox>
            )}
          </Container>
          <TablePagination
            className="page"
            rowsPerPageOptions={[8, 16, 24, 32, 40]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ pb: 3 }}
          />
        </MKBox>
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default withStyles(styles)(Gallery);
