/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import noData from "../../../../assets/images/no_data_found.png";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CardContent from "@mui/material/CardContent";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import "../Downloads/index.css";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/cover-2.webp";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import loader from "assets/images/loader.gif";
import dwldIcon from "assets/images/download.png";

import * as React from "react";
// Material Kit 2 React examples
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@apollo/client";
import { DOWNLOAD_DATAS } from "../../../../graphql/query/downloads";
import TopMenu from "../../../../components/TopMenu";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "2px solid #ff7900",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

function Downloads() {
  const [offset, setOffset] = React.useState(0);
  const [downloadDatas, setDownloadDatas] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState("");
  const { loading, error, data } = useQuery(DOWNLOAD_DATAS, {
    variables: {
      filterInput: {
        sortColumn: "id",
        sortOrder: "desc",
        search: searchData,
        offset,
        limit: rowsPerPage,
        status: true,
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setDownloadDatas([]);
      } else {
        setDownloadDatas(data.downloadDatas.downloads);
        setTotalCount(data.downloadDatas.totalCount);
      }
    }
  }, [data, loading, error]);
  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setOffset(0);
  };
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };
  return (
    <>
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25} className="fixed-top">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }} mt={5}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} className="fixed-top" />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          pt: { xs: 7 },
        }}
      >
        {" "}
        <MKTypography
          variant="h1"
          color="white"
          mt={-6}
          mb={1}
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
        >
          Downloads{" "}
        </MKTypography>
      </MKBox>
      <MKBox
        component="section"
        py={{ xs: 3, md: 12 }}
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mb: 10,
          mt: 8,
          backgroundColor: "#fff",
          boxShadow:
            "0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1),0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05)",
          borderRadius: "0.75rem",
        }}
      >
        <Container>
          <Box sx={{ flexGrow: 1 }} mb={3}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2 }}
                />
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={5}>
                    <Search sx={{ border: "2px solid #dcd6d4" }}>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search here..."
                        value={searchData}
                        onChange={(e) => handleSearch(e)}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
          {loading ? (
            <div className="row d-flex justify-content-center">
              <img alt="loader" src={loader} className="loader" />
            </div>
          ) : (
            <>
              {downloadDatas.length === 0 ? (
                <MKBox sx={{ justifyContent: "center", textAlign: "center" }}>
                  <img src={noData} width="300" />
                </MKBox>
              ) : (
                <Grid container alignItems="center">
                  {downloadDatas.map((ques) => (
                    <Grid item xs={3}>
                      <a
                        href={ques.file}
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="File"
                      >
                        <Card
                          sx={{ minWidth: 275, minHeight: 100, marginRight: "20px" }}
                          className="dwld_card"
                        >
                          <CardContent>
                            <MKTypography
                              variant="body2"
                              sx={{ fontSize: 16, textAlign: "center" }}
                              color="text"
                              pt={3}
                            >
                              &nbsp;&nbsp;
                              {ques.title}
                              <br />
                              <img width="50" src={dwldIcon} alt="" rel="" mr={2} />{" "}
                              {/* <MKBox
                          width="3rem"
                          height="3rem"
                          variant="gradient"
                          bgColor="info"
                          color="white"
                          coloredShadow="info"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                        >
                          <FileDownloadIcon />
                          <a
                            href={ques.file}
                            rel="noopener noreferrer"
                            target="_blank"
                            aria-label="File"
                          >
                            {ques.title} &nbsp;
                          </a>
                        </MKBox> */}
                            </MKTypography>
                          </CardContent>
                        </Card>
                      </a>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Container>
        <MKBox alignItems="center" className="page">
          <TablePagination
            rowsPerPageOptions={[12, 24, 36, 48, 60]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </MKBox>
      </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Downloads;
