import { gql } from "@apollo/client";

export const CONTENT = gql`
  query Query($contentsFilter: ContentFilterInput!) {
    contents(contentsFilter: $contentsFilter) {
      totalCount
      contents {
        id
        title
        type
        image
        status
        createdBy
        createdAt
      }
    }
  }
`;

export const GET_MANDATORYSUBMENU = gql`
  query getMandatoryList {
    getMandatoryList {
      id
      title
      slug
      sortOrder
      status
    }
  }
`;
export const GET_CONTENTBYMENU = gql`
  query GetContentBymenuid($mandatoryInput: ContentDisclosureInput!) {
    getContentBymenuid(mandatoryInput: $mandatoryInput) {
      id
      title
      image
      status
      createdBy
      createdAt
      type
      content_type
    }
  }
`;
