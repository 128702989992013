// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../../examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox/index";
import MKTypography from "../../../../../components/MKTypography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12}>
                <Item>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      description="Sahodaran Ayyappan Memorial College of Education is a part of Sree Narayana group of educational institutions managed by S.N.D.P. Yogam branch No. 1103 with a mission to ensure equal educational opportunities and to propagate quality education. The College started to function during the year 1995-96 getting directives from the Mahatma Gandhi University, Kottayam. The college has been accredited with 'A' Grade by National Assessment and Accreditation Council (NAAC) in 2004. Our Institution is the first self financing institution that was credited with 'A' grade by NAAC. The M.Ed. Department in the college started to function during the year 2006-07, getting directives from Mahatma Gandhi University, Kottayarn.

The college is situated near the Ernakulam - Kottayam Highway at Puthenkavu , adjacent to Sree Narayana Vallabha Temple, which sanctifies the noble mission of education. The B.Ed Programme focuses on eight optional subjects viz Malayalam, Hindi, English, Mathematics, Natural Science, Physical Science, Social Science and Commerce. The M.Ed. course is offered in Malayalam, English, Mathematics, Science, Social Science and Commerce. The college seeks to impart quality teacher education by adopting various ideological and technological advancements. The student teachers are given the opportunity to broaden their vision of education through educational seminars at National and State level, debates, discussions and symposiums. The college provides opportunities for cultural and physical activities for a balanced development of teacher personality. For nurturing the sports talent of student teacher the college organizes a biannual Badminton Tournament for the Sahodaran Ayyappan Memorial Ever Rolling Trophy with the participation of all colleges of Teacher Education affiliated to M.G. University Kottayam. The College organize an inter BEd collegiate art festival SAM FEST and a quiz competition for all B.Ed. Colleges under M.G. University, Kottayam. Besides these major inter BEd collegiate competitions, the college organizes a variety of cultural, literal and sports activities.       
                      "
                    />
                  </MKBox>
                </Item>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
