import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useQuery } from "@apollo/client";
import { EVENTS } from "../../../../graphql/query/events";
// Material Kit 2 React components
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import TopMenu from "../../../../components/TopMenu/index";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import loader from "assets/images/loader.gif";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../../../routes";
import footerRoutes from "../../../../footer.routes";

// Image
import bgImage from "../../../../assets/images/cover-1.webp";
import noData from "../../../../assets/images/no_data_found.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 25,
}));

function Events() {
  const [offset] = React.useState(0);
  const [eventDatas, setEventDatas] = React.useState([]);
  const { loading, error, data } = useQuery(EVENTS, {
    variables: {
      eventFilter: {
        status: true,
        limit: 10,
        offset,
        orderDirection: "desc",
        orderColumn: "id",
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setEventDatas([]);
      } else {
        setEventDatas(data.events.events);
      }
    }
  }, [data, loading, error]);

  return (
    <>
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox sx={{ py: { lg: 0.35, xs: 0 } }}>
        <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          pt: { xs: 5 },
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Events{" "}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      {loading ? (
        <div className="row d-flex justify-content-center">
          <img alt="loader" src={loader} className="loader" />
        </div>
      ) : (
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={12}
            xl={12}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 10, sm: 8, md: 10 }}
              mb={{ xs: 10, sm: 8, md: 10 }}
              mx={3}
            >
              {eventDatas.length ? (
                <Grid xs={12}>
                  {eventDatas.map((item) => (
                    <Item key={item.title}>
                      <MKBox mb={3}>
                        <Grid container spacing={8} alignItems="center">
                          <Grid item xs={12} lg={4}>
                            <img className="eventImg" src={item.image} />
                          </Grid>
                          <Grid item xs={12} lg={8}>
                            <DefaultInfoCard title={item.title} description={item.description} />
                          </Grid>
                        </Grid>
                      </MKBox>
                    </Item>
                  ))}
                </Grid>
              ) : (
                // <h3>No Data to Display</h3>
                <MKBox sx={{ justifyContent: "center", textAlign: "center" }}>
                  <img src={noData} width="300" />
                </MKBox>
              )}
            </MKBox>
          </Grid>
        </Grid>
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Events;
