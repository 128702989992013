import { gql } from "@apollo/client";

export const MANDATORYMENU = gql`
  query Query($mandatorysubmenuFilter: MandatorysubmenuFilterInput!) {
    mandatorysubmenus(mandatorysubmenuFilter: $mandatorysubmenuFilter) {
      totalCount
      mandatorysubmenu {
        id
        title
        slug
        sortOrder
        status
        createdBy
        createdAt
      }
    }
  }
`;
export const Mandatorysubmenu = gql`
  query GetMandatoryList($status: Boolean!) {
    getMandatoryList(status: $status) {
      id
      title
      status
      slug
      sortOrder
    }
  }
`;
