import { gql } from '@apollo/client';

export const DOWNLOAD_DATAS = gql`
  query DownloadDatas($filterInput: FilterDownloadsInput!) {
    downloadDatas(filterInput: $filterInput) {
      totalCount
      downloads {
        title
        status
        id
        file
        content_type
      }
    }
  }
`;

export const DOWNLOAD_DATA_BY_ID = gql`
  query DownloadData($downloadDataId: Int!) {
    downloadData(id: $downloadDataId) {
      id
      title
      file
      content_type
      created_by
      status
    }
  }
`;
