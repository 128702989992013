import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useQuery } from "@apollo/client";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import TopMenu from "../../../components/TopMenu/index";

// Material Kit 2 React examples
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";

// Image
import bgImage from "../../../assets/images/cover-2.webp";

function Downloads() {
  // const [mandatoryDatas, setMandatoryDatas] = React.useState([]);
  // const { loading, error, data } = useQuery(GET_ACTIVITYSUBMENUBYID, {
  //   variables: {
  //     type: 1,
  //   },
  // });
  // React.useEffect(() => {
  //   if (loading === false) {
  //     if (error) {
  //       setMandatoryDatas([]);
  //     } else {
  //       setMandatoryDatas(data.getContentBymenuid);
  //     }
  //   }
  // }, [data, loading, error]);

  return (
    <>
      <TopMenu variant="gradient" sx={{ px: { xs: 2, lg: 5 } }} />
      <MKBox variant="gradient" bgColor="info" shadow="sm" py={0.25}>
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox
        minHeight={{ xs: "18vh", md: "55vh" }}
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Material Kit 2 React{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Free & Open Source Web UI Kit built over ReactJS &amp; MUI. Join over 1.6 million
              developers around the world.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={12}
          xl={12}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <Grid item xs={12}>
              <Item>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    title="Bachelor of Education"
                    description="The duration of B.Ed programme will be two year with four semesters, having a break after one year on April-May.

                      Those students who have a minimum of 75% average attendance for all the courses can register for the examination. The student who have shortage of attendance should repeat the course along with the next batch.
                      
                      Admission to the B.Ed course is according to the MG University schedule. Admission in the merit seat for B.Ed course will be as per the centralized allotment procedure of the Government of Kerala. 50% of the seats will be filled by Management."
                  />
                </MKBox>
              </Item>
            </Grid>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Downloads;
