// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Material Kit 2",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "About",
      items: [
        { name: "The institution", route: "/about-us/institution" },
        { name: "Infrastructure", route: "/about-us/infrastructure" },
        { name: "Principals Message", route: "/about-us/principals-message" },
        { name: "Sister Concerns", route: "/about-us/sister-concern" },
      ],
    },
    {
      name: "Admission",
      items: [
        { name: "Admission Enquiry", route: "/admission/enquiry" },
        { name: "Admission Procedure", route: "/admission/procedure" },
        { name: "Fee Structure", route: "/admission/fee-structure" },
        { name: "Download", route: "/downloads" },
      ],
    },
    {
      name: "Events",
      items: [
        { name: "News", route: "/news" },
        { name: "Events", route: "/events" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" sx={{ color: "#fff" }}>
      All rights reserved. Copyright &copy; {date} Powered by{" "}
      <MKTypography
        component="a"
        href="https://techtaliya.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="bold"
        sx={{ color: "#fff" }}
      >
        TechTaliya
      </MKTypography>
    </MKTypography>
  ),
};
