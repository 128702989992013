/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ScrollToTop from "react-scroll-to-top";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import "../DefaultFooter/footer.css";
import bgFooter from "../../../assets/images/footer-bg.webp";
import samLogo from "../../../assets/images/sam-footer.png";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/contact-us`;
    navigate(path);
  };
  const footerRedirect = () => {
    window.scrollTo(0, 0);
  };

  return (
    <MKBox component="footer">
      <Grid
        container
        spacing={3}
        sx={{
          backgroundImage: `url(${bgFooter})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
        pb={{ xs: 5, md: 15 }}
        pt={{ xs: 5, md: 15 }}
      >
        <Grid item xs={12} md={5} sx={{ ml: "auto", mb: 3 }}>
          <MKBox px={{ xs: 5 }}>
            <MKTypography variant="h2" className="footer-head">
              Admission Enquiry
            </MKTypography>
            <MKButton sx={{ mt: 3 }} className="btn-white" onClick={routeChange} px={{ xs: 5 }}>
              Contact Us
            </MKButton>
          </MKBox>
          <Grid item xs={12} md={12} sx={{ ml: "auto", mb: 3 }} className="d-none">
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <MKTypography variant="h5" className="text-white">
                  About
                </MKTypography>
                <ul className="footer-list">
                  <li>
                    {" "}
                    <Link
                      className="footer-links"
                      to="/about-us/institution"
                      onClick={footerRedirect}
                    >
                      The Institution
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      className="footer-links"
                      to="/about-us/infrastructure"
                      onClick={footerRedirect}
                    >
                      Infrastructure
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      className="footer-links"
                      to="/about-us/principals-message"
                      onClick={footerRedirect}
                    >
                      Principal's Message
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      className="footer-links"
                      to="/about-us/sister-concern"
                      onClick={footerRedirect}
                    >
                      Sister Concerns
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={4} md={4}>
                <MKTypography variant="h5" className="text-white">
                  Admission
                </MKTypography>
                <ul className="footer-list">
                  <li>
                    {" "}
                    <Link className="footer-links" to="/admission/enquiry" onClick={footerRedirect}>
                      Admission Enquiry
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      className="footer-links"
                      to="/admission/procedure"
                      onClick={footerRedirect}
                    >
                      Admission Procedure
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link
                      className="footer-links"
                      to="/admission/fee-structure"
                      onClick={footerRedirect}
                    >
                      Fee Structure
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link className="footer-links" to="/downloads" onClick={footerRedirect}>
                      Download Application
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={4} md={4}>
                <MKTypography variant="h5" className="text-white">
                  Events
                </MKTypography>
                <ul className="footer-list">
                  <li>
                    {" "}
                    <Link className="footer-links" to="/news" onClick={footerRedirect}>
                      News
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link className="footer-links" to="/events" onClick={footerRedirect}>
                      Events
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          {/* <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox> */}
        </Grid>
        {menus.map(({ name: title, items }) => (
          <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }} className="d-none d-sm-block">
            <MKTypography
              display="block"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
              sx={{ color: "#fff" }}
              mb={1}
            >
              {title}
            </MKTypography>
            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
              {items.map(({ name, route, href }) => (
                <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                  {href ? (
                    <MKTypography
                      component="a"
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                      sx={{ color: "#fff" }}
                    >
                      {name}
                    </MKTypography>
                  ) : (
                    <MKTypography
                      component={Link}
                      to={route}
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                    >
                      {name}
                    </MKTypography>
                  )}
                </MKBox>
              ))}
            </MKBox>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} sx={{ backgroundColor: "rgba(10, 10, 10, 1)" }}>
        <Container>
          <div className="row py-3 d-flex align-items-center px-5" id="footer-bottom">
            <div className="col-sm-12 col-md-1 text-center-sm">
              <img src={samLogo} alt="SAM" />
            </div>
            <div className="col-sm-12 col-md-6 text-center-sm">
              <h2 className="footer-clg">
                sahodaran ayyappan memorial <br />
                college of education, poothotta
              </h2>
            </div>
            <div className="col-sm-12 col-md-5 text-center-sm">
              <p>
                Sahodharan Ayyappan Memorial College Of Education, Owned And
                <br /> Managed By SNDP Yogam 1103, Ernakulam, Poothotta Pin: 682307
                <br />
                <div className="row d-flex justify-content-start">
                  <div className="col-12">
                    <EmailIcon />
                    <span>&nbsp;samcoed@gmail.com</span>, &nbsp;
                    <PhoneForwardedIcon />
                    <span>&nbsp; 0484 2791218</span>
                  </div>
                </div>
              </p>
            </div>
          </div>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {copyright}
          </Grid>
        </Container>
      </Grid>
      <ScrollToTop smooth />
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
